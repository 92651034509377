.homeSection {
    background-color: #fff;
}

.articleSection {
    background-color: #fff;
}

.articleTitle {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #1f1e1e;

    @media (min-width: 992px) {
        font-size: 1.6rem;
    }
}

.articleSubTitle {
    text-align: center;
    margin-top: 0.4rem;
}

.subArticleTitle {
    text-align: center;
    font-size: 1rem;
    margin-top: 1rem;

    @media (min-width: 992px) {
        font-size: 1.2rem;
    }
}

.offersTitle {
    font-family: var(--font-raleway);
    font-weight: bold;
    font-size: 36px;
    padding-top: 30px;
}

.descriptionTitle {
    line-height: 1.8rem;
    margin-bottom: 1.5rem;

    @media (min-width: 992px) {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
}

.descriptionText {
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 300;
    padding-right: 2.5rem;
}

.carouselWrapper {
    background-color: #efefef;
    padding: 2rem;
}

.slider {
    padding: 2rem 0;
}

.categoryDescription {
    margin-top: 15px;

    @media (min-width: 992px) {
        padding: 3rem 0;
        margin-top: 35px;
    }
}

.categoryDescriptionRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.categoryDescriptionCol {
    display: flex;
    align-items: center;

    img {
        width: 100%;
        padding: 0.6rem;
        position: relative;
    }
}

.categoryDescriptionText {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 0;
    padding: 1rem 0.3rem;
    background: var(--primary-background-color);
    position: relative;
    font-size: 0.85rem;
    color: #fff;

    @media (min-width: 992px) {
        left: -20%;
        padding: 2.5rem;
        min-width: 650px;
        min-height: 400px;
        border-radius: 1rem;
    }

    p {
        line-height: 2rem;
        font-size: 1.1rem;
    }

    .cardTitle {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 2rem;

        @media (min-width: 992px) {
            font-size: 1.8rem;
        }
    }
}

.categoryWrapper {
    padding-bottom: 2.5rem;
}

.textElement {
    background-color: var(--primary-color);
    padding: 1rem;
    width: calc(100% - 2rem);
    border-radius: 1rem;
    margin-left: 1rem;
    position: absolute;
    height: 60px;
    bottom: -2.5rem;
    display: flex;
    align-items: center;
    vertical-align: middle;

    @media (min-width: 992px) {
        height: 80px;
    }

    h2 {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0;
        flex-grow: 1;
        line-height: 1.2rem;

        @media (min-width: 992px) {
            font-size: 1.15rem;
            line-height: 1.5rem;
        }
    }

    a {
        color: #fff;
    }
}

.imageCategory {
    height: 100%;
    width: 100%;
}

.greyFullSections {
    background: #f7f7f7;
    padding: 0.7rem 0;

    @media (min-width: 992px) {
        padding: 3rem 0;
    }
    margin: 1rem 0 0 0;
}

.main {
    display: flex;
    flex-direction: column;
}

.carouselBlock {
    display: block;
}

.carouselImage {
    height: 400px;
    width: 100vw;
}

.categoryWall {
    width: 65vw !important;
}

.categoryCarouselBlock {
    margin-top: 35px;
    width: 200px;
}

.narrowCol {
    margin-bottom: 0.5rem;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}

.RowItems {
    --bs-gutter-x: 0.6rem;
    margin-top: 1rem;
}
