.CarouselWrapper {
    position: relative;
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-color: var(--primary-color);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-size: 50% 50%;
        padding: 1.3rem;
    }

    @media (max-width: 767.98px) {
        background-color: var(--primary-background-color);
        color: #fff;

        [class="carousel-indicators"] {
            display: none !important;
        }

        .textDescription {
            padding: 1rem;
            text-align: center;

            h1 {
                @media (max-width: 767.98px) {
                    font-size: 1.2rem;
                }
            }

            p {
                @media (max-width: 767.98px) {
                    font-size: 0.9rem;
                }
            }
        }
    }

    .containerWrapper {
        @media (max-width: 767.98px) {
            position: absolute;
            top: 10%;
        }
    }

    .carouselImage {
        width: 100vw;
        aspect-ratio: 25 / 8;

        @media (min-width: 768px) {
            height: 482px;
        }
    }

    .textDescription {
        @media (min-width: 768px) {
            position: absolute;
            top: 25%;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            color: #fff;

            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: normal;
            max-width: 711px;

            h3 {
                margin-bottom: 2rem;
            }

            p {
                line-height: 1.5rem;
                font-weight: 500;
            }
        }
    }
}

.imageWrapper {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
}

.wrapperCarousel {
    position: relative;
    .customCarouselIndicators {
        padding: 0;
        position: absolute;
        bottom: 15%;
        list-style: none;
        display: flex;
        align-items: center;

        .indicatorButton {
            cursor: pointer;
            display: inline-block;
            margin: 0 9px 0 0;
            height: 12px;
            width: 12px;
            background-color: #fff;
            opacity: 0.5;
            border-radius: 50%;
            border: none;

            @media (max-width: 768px) {
                display: none;
            }

            &.active {
                height: 12px;
                width: 12px;
                opacity: 1;
            }
        }
    }
}
